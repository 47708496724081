import axiosInst from "@/plugins/axios";
// import { isAxiosError } from "axios";
import { defineStore } from "pinia";
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;
import { useSearchStore } from "./searchStore";
import { BigAlert, simpleError } from "@/plugins/notify/notify";

//store//
export const useSessionStore = defineStore("session", {
  state: () => ({
    config: {} as TypeConfig,
  }),
  actions: {
    // checkGlobalConfig() {},
    async fetchConfig() {
      await axiosInst()
        .get("config")
        .then(({ data }) => {
          if (data.result === "success" && data.data) {
            this.config = data?.data;
          } else {
            simpleError();
            console.error(data);
          }
        })
        .catch((e) => {
          simpleError();
          console.error(e);
        });
    },

    async createNewSession() {
      await axiosInst()
        .get("session")
        .then(({ data }) => {
          if (data?.result === "success" && data?.data) {
            SessionStorage.set("token", data.data);

            const onlSessionData = window.onlPromoPage
              ? { onlPromoPage: window.onlPromoPage }
              : {};
            axiosInst().post("session", [
              {
                externalId: window.onlExternalId || "",
                onlSessionData: onlSessionData,
                onlSessionType: "BOOKING",
              },
            ]);
          } else {
            console.error(data);
            simpleError();
          }
        })
        .catch((e) => {
          console.error(e);
          simpleError();
        });
    },

    // eslint-disable-next-line
    callBack(action: string, data: any) {
      if (window.onlCallback) {
        console.log(typeof window.onlCallback);

        const session = SessionStorage.getItem("token");
        try {
          setTimeout(window.onlCallback(session, action, data), 0);
        } catch (e) {
          console.error("onlCallback error", e);
        }
      }
    },

    timeoutError() {
      BigAlert({
        type: "warning",
        title: "Время сессии истекло!",
        message: "Количество свободных мест могло измениться.",
      });

      SessionStorage.remove("token");
      useSearchStore().$reset();
    },
  },

  getters: {
    baseUrl: () => {
      return window.onlBaseURL;
    },
  },
});

//types
type TypeConfig = {
  max_days: number;
  calc_night: boolean;
  min_child_age: number;
  max_child_age: number;
  default_days: number;
  consent_url: string;
  consent_text: string;
  guest_fio_required: boolean;
  use_html: boolean;
  server_date: string;
};

declare global {
  interface Window {
    onlAPIKey?: string;
    onlBaseURL?: string;
    onlExternalId?: string;
    onlPromoPage?: string;

    onlDivision?: string;
    // onlBillTheme?: TypeBillTheme;

    // eslint-disable-next-line
    onlCallback?: any;

    onlTheme?: {
      primary?: string;
      negative?: string;
      warning?: string;
      background?: string;
    };

    onlDefaults: {
      dateSelectorOpen?: boolean; //открытие окна выбора дат при загрузке модуля

      dateIn?: string; //дата заезда по умолчанию
      dateInDays?: number; //дата заезда по умолчанию (смещение на X дней от текущей даты)

      days?: number;

      // гости
      rooms?: {
        adults: number; // количество взрослых
        children: number[]; // возраст детей через запятую children: [10, 6, 12]
      }[];

      autoSearch?: boolean; // автоматический поиск номеров после загрузки модуля
      autoSelectSingleTariff?: boolean; // автоматический выбор тарифа, если доступен только один тариф

      finalMessage?: {
        title: string;
        message: string;
      };
    };
  }
}
