<template>
  <div id="onl4-wrapper">
    <div v-if="division && baseURL && APIKey">
      <div v-if="Object.keys(config).length">
        <date-selector />

        <q-stepper
          @update:model-value="log('update')"
          @transition="log('transition')"
          :style="{ 'background-color': bg }"
          v-if="step != -1"
          header-nav
          v-model="step"
          ref="stepper"
          color="primary"
          class="q-pa-sm"
          animated
          vertical>
          <!-- Номер -->
          <q-step
            v-for="(room, idx) of rooms"
            :key="idx"
            :name="idx"
            :title="
              'Номер ' +
              (idx + 1) +
              (room.selectedRoom ? ' : ' + room.selectedRoom.description : '')
            "
            icon="mdi-bed-king"
            active-icon="mdi-bed-king"
            :done="room.addRoomResult ? true : false"
            :header-nav="true">
            <main-selector />
          </q-step>

          <!-- Данные гостей -->
          <q-step
            :name="rooms.length"
            :title="'Данные гостей'"
            icon="mdi-bed-king"
            active-icon="mdi-bed-king"
            :done="step > rooms.length"
            :header-nav="step >= rooms.length">
            <guest-info />
          </q-step>

          <!-- Оплата -->
          <q-step
            :name="rooms.length + 1"
            :title="'Оплата'"
            icon="mdi-bed-king"
            active-icon="mdi-bed-king"
            :header-nav="false">
            <payment-type-selector />
          </q-step>
        </q-stepper>
      </div>
    </div>

    <div v-else>
      <q-banner
        style="margin-bottom: 20px"
        class="bg-red text-white text-center">
        <div>
          <div class="text-h6">Онлайн бронирование недоступно!</div>
          <br />
          <div v-if="!baseURL">Не инициализирован window.onlBaseURL!</div>
          <div v-if="!division">Не инициализирован window.onlDivision!</div>
          <div v-if="!APIKey">Не инициализирован window.onlAPIKey!</div>
        </div>
      </q-banner>
    </div>

    <wait-payment-dialog />
  </div>
</template>

<script lang="ts">
import "@/styles/icons/fa/fontawesome-v6.css";
import "@/styles/icons/material-icons/material-icons.css";
import "@/styles/icons/mdi-v4/mdi-v4.css";

import { defineComponent } from "vue";

import DateSelector from "./components/DateSelector.vue";

import { mapActions, mapState, mapWritableState } from "pinia";
import { useSessionStore } from "./stores/sessionStore";
import { useSearchStore } from "./stores/searchStore";

import moment from "moment";

import MainSelector from "./components/MainSelector.vue";
import GuestInfo from "./components/GuestInfo.vue";
import PaymentTypeSelector from "./components/PaymentTypeSelector.vue";
import { usePaymentStore } from "./stores/paymentStore";
import WaitPaymentDialog from "./components/WaitPaymentDialog.vue";

//
if (process.env.NODE_ENV !== "production") {
  window.onlDivision = "b68673d4-52cb-42f6-9942-7bb86639c628";
  window.onlBaseURL = "http://online-demo.resortshop.ru";
  window.onlAPIKey = "542bc4eeec6f435fbb62dca379da92fe";
  // window.onlPromoPage = "MIR-CASHBACK-2020";
}
//

export default defineComponent({
  name: "LayoutDefault",

  data: () => ({
    division: window.onlDivision || undefined,
    baseURL: window.onlBaseURL || undefined,
    APIKey: window.onlAPIKey || undefined,
    bg: window.onlTheme?.background || undefined,
  }),

  methods: {
    log(v: any) {
      console.log(v);
    },
    ...mapActions(useSessionStore, ["createNewSession", "fetchConfig"]),
    ...mapActions(useSearchStore, ["cancelAllRooms", "findRooms"]),
    ...mapActions(usePaymentStore, ["checkPayment"]),
  },

  computed: {
    ...mapState(useSearchStore, ["rooms", "selectedDateRange"]),
    ...mapState(useSessionStore, ["config"]),
    ...mapWritableState(useSearchStore, ["dateSelectorDialog", "step"]),
  },

  async created() {
    console.log("created app");

    const urlParams = new URLSearchParams(window.location.search);

    const paymentId = urlParams.get("paymentId") || undefined;
    const paramToken = urlParams.get("session") || undefined;
    const division = urlParams.get("division") || undefined;
    const returnResult = urlParams.get("return") || undefined;

    if (
      paramToken &&
      paymentId &&
      returnResult === "success" &&
      division == window.onlDivision
    ) {
      await this.checkPayment(paramToken, Number(paymentId));
    }

    await this.createNewSession();
    await this.fetchConfig();

    const DEFAULT_DAYS = 7;
    this.selectedDateRange[0] = moment().add(DEFAULT_DAYS, "days").toDate();
    this.selectedDateRange[1] = moment()
      .add(
        DEFAULT_DAYS +
          this.config.default_days +
          (this.config.calc_night ? 0 : -1),
        "days"
      )
      .toDate();

    // глабальные переменные

    if (window.onlDefaults) {
      // открытие диалога дат
      if (window.onlDefaults.dateSelectorOpen == true && this.step == -1) {
        this.dateSelectorDialog = true;
      }

      // даты заезда
      if (window.onlDefaults.dateIn) {
        let days = this.config.default_days;
        if (window.onlDefaults.days) days = window.onlDefaults.days;

        this.selectedDateRange[0] = moment(
          window.onlDefaults.dateIn,
          "DD.MM.YYYY"
        )
          .startOf("day")
          .toDate();

        this.selectedDateRange[1] = moment(
          window.onlDefaults.dateIn,
          "DD.MM.YYYY"
        )
          .startOf("day")
          .add(days, "days")
          .toDate();
      }

      // даты заезда смещение
      if (window.onlDefaults.dateInDays) {
        let days = this.config.default_days;
        if (window.onlDefaults.days) days = window.onlDefaults.days;

        this.selectedDateRange[0] = moment()
          .add(window.onlDefaults.dateInDays, "days")
          .startOf("day")
          .toDate();

        this.selectedDateRange[1] = moment()
          .add(window.onlDefaults.dateInDays, "days")
          .startOf("day")
          .add(days, "days")
          .toDate();
      }

      if (window.onlDefaults.rooms?.length) {
        this.rooms.length = 0;
        window.onlDefaults.rooms.forEach((room) => {
          this.rooms.push({ search: room });
        });
      }

      if (window.onlDefaults.autoSearch == true) {
        this.findRooms();
      }
    }

    //параметры в запросе
    const dateIn = urlParams.get("dateIn") || undefined;
    const dateOut = urlParams.get("dateOut") || undefined;

    if (dateIn) {
      this.selectedDateRange[0] = moment(dateIn, "DD.MM.YYYY")
        .startOf("day")
        .toDate();
    }
    if (dateOut) {
      this.selectedDateRange[1] = moment(dateOut, "DD.MM.YYYY")
        .startOf("day")
        .toDate();
    }
    //окно диалога
    const openSelector = urlParams.get("dateSelectorOpen") || undefined;
    if (openSelector == "true" && this.step == -1) {
      this.dateSelectorDialog = true;
    }

    //авто поиск
    const autoSearch = urlParams.get("autoSearch") || undefined;
    if (autoSearch == "true" && this.step == -1) {
      this.findRooms();
    }

    //создаем сессию если нет токена или он старше 5 минут
    // const token = SessionStorage.getItem("token") as {
    //   createTime: Date;
    //   token: string;
    // };
    // if (!token || !(moment(token?.createTime).diff(moment(), "minutes") > -5)) {
    //   await this.createNewSession();
    // }

    // вы точно хотите уйти?
    // if (process.env.NODE_ENV !== "production") {
    //   const cancelAllRooms = () => this.cancelAllRooms();
    //   window.onbeforeunload = function () {
    //     // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //     cancelAllRooms();
    //     return "Close prompt";
    //   };
    // }
  },

  mounted() {
    const targetContainer = document.getElementById("appONL4Modals");

    if (targetContainer) {
      const foundModal = document.querySelector("#q-notify");
      console.log(foundModal);
      if (foundModal) {
        targetContainer.append(foundModal);
      }
    }
  },

  components: {
    DateSelector,
    MainSelector,
    GuestInfo,
    PaymentTypeSelector,
    WaitPaymentDialog,
  },
});
</script>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.q-timeline__dot {
  display: none;
}

.q-stepper__step-inner {
  padding: 0px 10px 10px 40px !important;
}

.q-stepper__tab {
  padding: 10px !important;
}

.q-timeline__entry {
  padding-left: 5px !important;
}

::-webkit-scrollbar {
  width: 10px; /* в основном для вертикальных полос прокрутки */
  height: 10px; /* в основном для горизонтальных полос прокрутки */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* цвет дорожки */
  border-radius: 20px; /* закругления плашки */
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}
</style>

<style>
.q-stepper--horizontal .q-stepper__step-inner {
  padding: 0;
}

.test {
  opacity: 0;
}
</style>

<style>
#onl4-wrapper * {
  animation: revert;
  animation-delay: revert;
  animation-direction: revert;
  animation-duration: revert;
  animation-fill-mode: revert;
  animation-iteration-count: revert;
  animation-name: revert;
  animation-play-state: revert;
  animation-timing-function: revert;
  backface-visibility: revert;
  background: revert;
  background-attachment: revert;
  background-clip: revert;
  background-color: revert;
  background-image: revert;
  background-origin: revert;
  background-position: revert;
  background-position-x: revert;
  background-position-y: revert;
  background-repeat: revert;
  background-size: revert;
  border: revert;
  border-style: revert;
  border-width: revert;
  border-color: revert;
  border-bottom: revert;
  border-bottom-color: revert;
  border-bottom-left-radius: revert;
  border-bottom-right-radius: revert;
  border-bottom-style: revert;
  border-bottom-width: revert;
  border-collapse: revert;
  border-image: revert;
  border-left: revert;
  border-left-color: revert;
  border-left-style: revert;
  border-left-width: revert;
  border-radius: revert;
  border-right: revert;
  border-right-color: revert;
  border-right-style: revert;
  border-right-width: revert;
  border-spacing: revert;
  border-top: revert;
  border-top-color: revert;
  border-top-left-radius: revert;
  border-top-right-radius: revert;
  border-top-style: revert;
  border-top-width: revert;
  bottom: revert;
  box-shadow: revert;
  box-sizing: revert;
  caption-side: revert;
  clear: revert;
  clip: revert;
  color: revert;
  columns: revert;
  column-count: revert;
  column-fill: revert;
  column-gap: revert;
  column-rule: revert;
  column-rule-color: revert;
  column-rule-style: revert;
  column-rule-width: revert;
  column-span: revert;
  column-width: revert;
  content: revert;
  counter-increment: revert;
  counter-reset: revert;
  cursor: revert;
  direction: revert;
  display: revert;
  empty-cells: revert;
  float: revert;
  font: revert;
  font-family: revert;
  font-size: revert;
  font-style: revert;
  font-variant: revert;
  font-weight: revert;
  height: revert;
  hyphens: revert;
  left: revert;
  letter-spacing: revert;
  line-height: revert;
  list-style: revert;
  list-style-image: revert;
  list-style-position: revert;
  list-style-type: revert;
  margin: revert;
  margin-bottom: revert;
  margin-left: revert;
  margin-right: revert;
  margin-top: revert;
  max-height: revert;
  max-width: revert;
  min-height: revert;
  min-width: revert;
  opacity: revert;
  orphans: revert;
  outline: revert;
  outline-color: revert;
  outline-style: revert;
  outline-width: revert;
  overflow: revert;
  overflow-x: revert;
  overflow-y: revert;
  padding: revert;
  padding-bottom: revert;
  padding-left: revert;
  padding-right: revert;
  padding-top: revert;
  page-break-after: revert;
  page-break-before: revert;
  page-break-inside: revert;
  perspective: revert;
  perspective-origin: revert;
  position: revert;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: revert;
  tab-size: revert;
  table-layout: revert;
  text-align: revert;
  text-align-last: revert;
  text-decoration: revert;
  text-decoration-color: revert;
  text-decoration-line: revert;
  text-decoration-style: revert;
  text-indent: revert;
  text-shadow: revert;
  text-transform: revert;
  top: revert;
  transform: revert;
  transform-style: revert;
  transition: revert;
  transition-delay: revert;
  transition-duration: revert;
  transition-property: revert;
  transition-timing-function: revert;
  unicode-bidi: revert;
  vertical-align: revert;
  visibility: revert;
  white-space: revert;
  widows: revert;
  width: revert;
  word-spacing: revert;
  z-index: revert;
  /* basic modern patch */
  /* all: initial;
  all: unset; */
}

#onl4-wrapper * ::after {
  animation: revert;
  animation-delay: revert;
  animation-direction: revert;
  animation-duration: revert;
  animation-fill-mode: revert;
  animation-iteration-count: revert;
  animation-name: revert;
  animation-play-state: revert;
  animation-timing-function: revert;
  backface-visibility: revert;
  background: revert;
  background-attachment: revert;
  background-clip: revert;
  background-color: revert;
  background-image: revert;
  background-origin: revert;
  background-position: revert;
  background-position-x: revert;
  background-position-y: revert;
  background-repeat: revert;
  background-size: revert;
  border: revert;
  border-style: revert;
  border-width: revert;
  border-color: revert;
  border-bottom: revert;
  border-bottom-color: revert;
  border-bottom-left-radius: revert;
  border-bottom-right-radius: revert;
  border-bottom-style: revert;
  border-bottom-width: revert;
  border-collapse: revert;
  border-image: revert;
  border-left: revert;
  border-left-color: revert;
  border-left-style: revert;
  border-left-width: revert;
  border-radius: revert;
  border-right: revert;
  border-right-color: revert;
  border-right-style: revert;
  border-right-width: revert;
  border-spacing: revert;
  border-top: revert;
  border-top-color: revert;
  border-top-left-radius: revert;
  border-top-right-radius: revert;
  border-top-style: revert;
  border-top-width: revert;
  bottom: revert;
  box-shadow: revert;
  box-sizing: revert;
  caption-side: revert;
  clear: revert;
  clip: revert;
  color: revert;
  columns: revert;
  column-count: revert;
  column-fill: revert;
  column-gap: revert;
  column-rule: revert;
  column-rule-color: revert;
  column-rule-style: revert;
  column-rule-width: revert;
  column-span: revert;
  column-width: revert;
  content: revert;
  counter-increment: revert;
  counter-reset: revert;
  cursor: revert;
  direction: revert;
  display: revert;
  empty-cells: revert;
  float: revert;
  font: revert;
  font-family: revert;
  font-size: revert;
  font-style: revert;
  font-variant: revert;
  font-weight: revert;
  height: revert;
  hyphens: revert;
  left: revert;
  letter-spacing: revert;
  line-height: revert;
  list-style: revert;
  list-style-image: revert;
  list-style-position: revert;
  list-style-type: revert;
  margin: revert;
  margin-bottom: revert;
  margin-left: revert;
  margin-right: revert;
  margin-top: revert;
  max-height: revert;
  max-width: revert;
  min-height: revert;
  min-width: revert;
  opacity: revert;
  orphans: revert;
  outline: revert;
  outline-color: revert;
  outline-style: revert;
  outline-width: revert;
  overflow: revert;
  overflow-x: revert;
  overflow-y: revert;
  padding: revert;
  padding-bottom: revert;
  padding-left: revert;
  padding-right: revert;
  padding-top: revert;
  page-break-after: revert;
  page-break-before: revert;
  page-break-inside: revert;
  perspective: revert;
  perspective-origin: revert;
  position: revert;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: revert;
  tab-size: revert;
  table-layout: revert;
  text-align: revert;
  text-align-last: revert;
  text-decoration: revert;
  text-decoration-color: revert;
  text-decoration-line: revert;
  text-decoration-style: revert;
  text-indent: revert;
  text-shadow: revert;
  text-transform: revert;
  top: revert;
  transform: revert;
  transform-style: revert;
  transition: revert;
  transition-delay: revert;
  transition-duration: revert;
  transition-property: revert;
  transition-timing-function: revert;
  unicode-bidi: revert;
  vertical-align: revert;
  visibility: revert;
  white-space: revert;
  widows: revert;
  width: revert;
  word-spacing: revert;
  z-index: revert;
  /* basic modern patch */
  /* all: initial;
  all: unset; */
}

#onl4-wrapper *::before {
  animation: revert;
  animation-delay: revert;
  animation-direction: revert;
  animation-duration: revert;
  animation-fill-mode: revert;
  animation-iteration-count: revert;
  animation-name: revert;
  animation-play-state: revert;
  animation-timing-function: revert;
  backface-visibility: revert;
  background: revert;
  background-attachment: revert;
  background-clip: revert;
  background-color: revert;
  background-image: revert;
  background-origin: revert;
  background-position: revert;
  background-position-x: revert;
  background-position-y: revert;
  background-repeat: revert;
  background-size: revert;
  border: revert;
  border-style: revert;
  border-width: revert;
  border-color: revert;
  border-bottom: revert;
  border-bottom-color: revert;
  border-bottom-left-radius: revert;
  border-bottom-right-radius: revert;
  border-bottom-style: revert;
  border-bottom-width: revert;
  border-collapse: revert;
  border-image: revert;
  border-left: revert;
  border-left-color: revert;
  border-left-style: revert;
  border-left-width: revert;
  border-radius: revert;
  border-right: revert;
  border-right-color: revert;
  border-right-style: revert;
  border-right-width: revert;
  border-spacing: revert;
  border-top: revert;
  border-top-color: revert;
  border-top-left-radius: revert;
  border-top-right-radius: revert;
  border-top-style: revert;
  border-top-width: revert;
  bottom: revert;
  box-shadow: revert;
  box-sizing: revert;
  caption-side: revert;
  clear: revert;
  clip: revert;
  color: revert;
  columns: revert;
  column-count: revert;
  column-fill: revert;
  column-gap: revert;
  column-rule: revert;
  column-rule-color: revert;
  column-rule-style: revert;
  column-rule-width: revert;
  column-span: revert;
  column-width: revert;
  content: revert;
  counter-increment: revert;
  counter-reset: revert;
  cursor: revert;
  direction: revert;
  display: revert;
  empty-cells: revert;
  float: revert;
  font: revert;
  font-family: revert;
  font-size: revert;
  font-style: revert;
  font-variant: revert;
  font-weight: revert;
  height: revert;
  hyphens: revert;
  left: revert;
  letter-spacing: revert;
  line-height: revert;
  list-style: revert;
  list-style-image: revert;
  list-style-position: revert;
  list-style-type: revert;
  margin: revert;
  margin-bottom: revert;
  margin-left: revert;
  margin-right: revert;
  margin-top: revert;
  max-height: revert;
  max-width: revert;
  min-height: revert;
  min-width: revert;
  opacity: revert;
  orphans: revert;
  outline: revert;
  outline-color: revert;
  outline-style: revert;
  outline-width: revert;
  overflow: revert;
  overflow-x: revert;
  overflow-y: revert;
  padding: revert;
  padding-bottom: revert;
  padding-left: revert;
  padding-right: revert;
  padding-top: revert;
  page-break-after: revert;
  page-break-before: revert;
  page-break-inside: revert;
  perspective: revert;
  perspective-origin: revert;
  position: revert;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: revert;
  tab-size: revert;
  table-layout: revert;
  text-align: revert;
  text-align-last: revert;
  text-decoration: revert;
  text-decoration-color: revert;
  text-decoration-line: revert;
  text-decoration-style: revert;
  text-indent: revert;
  text-shadow: revert;
  text-transform: revert;
  top: revert;
  transform: revert;
  transform-style: revert;
  transition: revert;
  transition-delay: revert;
  transition-duration: revert;
  transition-property: revert;
  transition-timing-function: revert;
  unicode-bidi: revert;
  vertical-align: revert;
  visibility: revert;
  white-space: revert;
  widows: revert;
  width: revert;
  word-spacing: revert;
  z-index: revert;
  /* basic modern patch */
  /* all: initial;
  all: unset; */
}
</style>

<style>
body {
  min-height: 0 !important;
}
</style>
