import axiosInst from "@/plugins/axios";
import { defineStore } from "pinia";
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;
import { useSearchStore } from "./searchStore";
import { useSessionStore } from "./sessionStore";
import {
  notify,
  simpleError,
  connectionError,
  BigAlert,
} from "@/plugins/notify/notify";
import moment from "moment";

import openURL from 'quasar/src/utils/open-url.js';;

//store//
export const usePaymentStore = defineStore("payment", {
  state: () => ({
    paymentTypes: [] as TypePaymentType[],

    waitPayment: false,
    lastPaymentTypeId: 0,

    redirectUrl: "",

    isCheckCancel: false,
  }),
  actions: {
    async getPaymentTypes() {
      await axiosInst()
        .post("/payment_type", [
          {
            division: window.onlDivision,
            lang: "ru",
          },
        ])
        .then(({ data }) => {
          this.paymentTypes = data.data;
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            useSessionStore().timeoutError();
            return;
          }
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });
    },

    async payment(paymentTypeId: number, paymentMethod: TypePaymentMethod) {
      this.waitPayment = true;

      // callBack

      useSessionStore().callBack(
        "addRoomToCart",
        this.paymentTypes.find((item) => item.Id == paymentTypeId)
      );
      //

      await axiosInst()
        .post("/payment", [
          {
            URL: window.location.href,
            division: window.onlDivision,
            paymentTypeId: paymentTypeId,
            paymentMethod: paymentMethod,
          },
        ])
        .then((res) => {
          const paymentRes: TypePaymentRes = res.data;

          if (paymentRes?.result === "success") {
            // оплата картой
            if (paymentMethod == "card" && paymentRes?.redirectURL) {
              // открыть в новой вкладке
              const token = SessionStorage.getItem("token")?.toString();
              // window.open(paymentRes.redirectURL, "_blank");
              this.redirectUrl = paymentRes.redirectURL;
              openURL(paymentRes.redirectURL);

              if (token) this.checkPayment(token, paymentTypeId);
              return;
            }

            if (paymentMethod == "sbp" && paymentRes?.redirectURL) {
              // открыть в новой вкладке
              const token = SessionStorage.getItem("token")?.toString();
              // window.open(paymentRes.sbpURL, "_blank");
              this.redirectUrl = paymentRes.sbpURL;
              openURL(paymentRes.sbpURL);

              if (token) this.checkPayment(token, paymentTypeId);
              return;
            }

            if (paymentMethod == "deep" && paymentRes?.redirectURL) {
              // открыть в новой вкладке
              const token = SessionStorage.getItem("token")?.toString();

              if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                  navigator.userAgent
                )
              ) {
                // window.open(paymentRes.deepLink, "_blank");
                this.redirectUrl = paymentRes.deepLink;
                openURL(paymentRes.deepLink);
              } else {
                if (paymentRes?.redirectURL) {
                  // window.open(paymentRes?.redirectURL, "_blank");
                  this.redirectUrl = paymentRes.redirectURL;
                  openURL(paymentRes?.redirectURL);
                }
              }

              this.lastPaymentTypeId = paymentTypeId;
              if (token) this.checkPayment(token, paymentTypeId);
              return;
            }

            if (paymentRes.message === "Операция успешно завершена.") {
              BigAlert({
                type: "positive",
                title:
                  window.onlDefaults?.finalMessage?.title ||
                  "Вы забронированы!",
                message:
                  window.onlDefaults?.finalMessage?.message ||
                  "Ожидайте письмо подтверждения на указанную вами почту.",
              });

              SessionStorage.remove("token");

              useSearchStore().$reset();
              useSearchStore().selectedDateRange = [
                moment().add(7, "days").toDate(),
                moment().add(14, "days").toDate(),
              ];

              return;
            }
          }

          if (paymentRes?.result === "error") {
            notify({
              type: "negative",
              message: "Ошибка!",
              caption: paymentRes.resultMessage,
            });

            return;
          }
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            useSessionStore().timeoutError();
            return;
          }
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });

      this.waitPayment = false;
    },

    async checkPayment(token: string, paymentTypeId: number, once?: boolean) {
      await axiosInst()
        .post(
          "/payment",
          [
            {
              division: window.onlDivision,
              paymentTypeId: paymentTypeId,
            },
          ],
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          const paymentRes: TypePaymentRes = res.data;
          switch (paymentRes.result) {
            case "success": {
              BigAlert({
                type: "positive",
                title:
                  window.onlDefaults?.finalMessage?.title ||
                  "Вы забронированы!",
                message:
                  window.onlDefaults?.finalMessage?.message ||
                  "Ожидайте письмо подтверждения на указанную вами почту.",
              });
              this.waitPayment = false;
              SessionStorage.remove("token");
              useSearchStore().$reset();
              useSearchStore().selectedDateRange = [
                moment().add(7, "days").toDate(),
                moment().add(14, "days").toDate(),
              ];
              break;
            }
            case "wait": {
              if (once) {
                this.waitPayment = false;
              } else {
                this.waitPayment = true;
                setTimeout(() => {
                  if (this.waitPayment) this.checkPayment(token, paymentTypeId);
                }, 5000);
              }

              break;
            }
            case "error": {
              notify({
                type: "negative",
                message: "Ошибка оплаты!",
                caption: "Повторите попытку.",
              });

              this.waitPayment = false;
              break;
            }

            default:
              {
                this.waitPayment = false;
              }
              break;
          }
        })
        .catch(async (e) => {
          this.waitPayment = false;
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });
    },

    async cancelPayment() {
      this.isCheckCancel = true;

      // еще раз проверить через 5 секунд
      const token = SessionStorage.getItem("token")?.toString();
      if (!token) return;

      setTimeout(async () => {
        await this.checkPayment(token, this.lastPaymentTypeId, true);
        this.isCheckCancel = false;
      }, 4000);

      // axiosInst()
      //   .post("/payment", [
      //     {
      //       division: window.onlDivision,
      //       paymentTypeId: this.lastPaymentTypeId,
      //       cancel: true,
      //     },
      //   ])
      //   .then((res) => {
      //     const cancelRes: TypePaymentRes = res.data;

      //     if (cancelRes.result == "success") {
      //       this.waitPayment = false;
      //     }

      //     if (cancelRes.result == "error") {
      //       notify({
      //         type: "negative",
      //         message: "Ошибка отмены оплаты!",
      //         caption: cancelRes.message,
      //       });
      //     }
      //   })

      //   .catch((e) => {
      //     if (!e?.response?.status) {
      //       connectionError();
      //       return;
      //     }
      //     simpleError();
      //   });
    },
  },

  getters: {},
});

type TypePaymentType = {
  Id: number;
  Description: string;
  PayType: "CARD" | "NOPAY";
  Info: string;
  PayProc: number;
  PayKd: number;
  PaySum: number;
  TotalSum: number;

  allowCard: boolean;
  allowDeep: boolean;
  allowSPB: boolean;
  bank: TypeBanks;
};

type TypeBanks =
  | "SBRFE_TEST"
  | "SBRFE"
  | "SBRF_TEST"
  | "SBRF"
  | "ALFA_TEST"
  | "ALFA"
  | "CLOUD"
  | "GPB_TEST"
  | "GPB"
  | "RSHB_TEST"
  | "RSHB";

type TypePaymentMethod = "card" | "deep" | "sbp" | "nopay";

type TypePaymentRes = {
  result: "success" | "wait" | "error";
  message: string;
  resultMessage: string;
  redirectURL: string;
  sbpURL: string;
  deepLink: string;

  widget: boolean;
  widgetData: unknown;
};

export { TypeBanks, TypePaymentMethod };
