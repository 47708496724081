import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { createPinia } from "pinia";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

createApp(App)
  .use(createPinia())

  // eslint-disable-next-line
  .use(VueTelInput as any)
  .use(Quasar, quasarUserOptions)
  .mount("#appONL4");
/* eslint-disable */
// @ts-nocheck

const targetContainer = document.getElementById("appONL4Modals");

if (targetContainer) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node:any) => {
        if (
          node.nodeType === 1 &&
          node.id.startsWith("q-portal") &&
          node.parentNode !== targetContainer
        ) {
          targetContainer.appendChild(node);
        }
      });
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });
} else {
  console.error("Target container #appONL4Modals not found");
}
